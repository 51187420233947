import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import Chilli5 from "../../icon/chilli5.svg";
import Chilli4 from "../../icon/chilli4.svg";
import Chilli3 from "../../icon/chilli3.svg";
import Chilli2 from "../../icon/chilli2.svg";
import Chilli1 from "../../icon/chilli1.svg";
import FundPopover from "./fundPopover";


import EmptyChilli2345 from "../../icon/chilli-empty2345.svg";
import EmptyChilli1 from "../../icon/chilli-empty1.svg";

const useStyles = makeStyles((theme) => ({
  ratingImage: {
    padding: "2px",
  },
  ratingImageLargeVariant:{
    padding: "3px",
  },
  title: {
    color: "#8C8CA1",
    paddingBottom: "8px",
  },
  riskContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingBottom: "22px",
    },
  },
  dangerCircle:{
    paddingLeft: "4px",
  }
}));

export default function Risk(props) {
  const classes = useStyles();
  let Chilli;
  let EmptyChilli = EmptyChilli2345;
  if (props.rating == 5) {
    Chilli = Chilli5;
  }
  if (props.rating == 4) {
    Chilli = Chilli4;
  }
  if (props.rating == 3) {
    Chilli = Chilli3;
  }
  if (props.rating == 2) {
    Chilli = Chilli2;
  }
  if (props.rating == 1) {
    Chilli = Chilli1;
    EmptyChilli = EmptyChilli1;
  }
  if (props.rating==0) {
    return (
      <Grid>
        <FundPopover title="Risk" disclaimerLocation="fund_list_disclaimer_text_risk" />
        n/a
      </Grid>
    );
  }
  else {
    return (
      <Grid className={props.isList !== "true" && classes.riskContainer}>
        <FundPopover title="Risk" disclaimerLocation="fund_list_disclaimer_text_risk" />
        <Rating
          readOnly
          name="Rating"
          precision={1}
          value={props.rating}
          icon={
            <img src={Chilli} alt="ChilliIcon" className={props.isLargeVariant ? classes.ratingImageLargeVariant : classes.ratingImage} />
          }
          emptyIcon={
            <img
              src={EmptyChilli}
              alt="EmptyMildChilliIcon"
              className={props.isLargeVariant ? classes.ratingImageLargeVariant : classes.ratingImage}
            />
          }
        />
      </Grid>
    );
  }
 
}
