import { useForm, Controller } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import DangerTriangle from "../icon/DangerTriangle.svg";
import CloseSquare from "../icon/CloseSquare.svg";
import ArrowRightSquare from "../icon/ArrowRightSquare.svg";
import ArrowLeft from "../icon/ArrowLeft.svg";
import Rating from '@material-ui/lab/Rating';
import RateFundStep3MessageSent from "./components/rateFundStep3MessageSent";
import Send from "../icon/Send.svg";
import StarIcon from "../icon/Star.svg";
import EmptyStarIcon from "./img/empty-star.svg";
import { createClient } from "../../lib/helpers";
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import Recaptcha from "react-recaptcha";
import { useStaticQuery, graphql } from "gatsby";
import RecaptchaLoader from "../../lib/RecaptchaLoader";
let recaptchaInstance;
const executeCaptcha = (e) => {
  e.preventDefault();
  recaptchaInstance.reset();
  recaptchaInstance.execute();

};




const useStyles = makeStyles((theme) => ({
  starImage: {
    paddingRight: ".2em",
    height: ".85em",
  },
  sendAMessage: {
    color: "#3C455C",
    width: '80%'
  },
  collapsePopupButton: {
    minWidth: "24px !important",
    width: 24,
    height: 24,
    position: 'absolute',
    top: 0,
    right: 0,
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  popupHeader: {
    marginBottom: "1em",
  },
  yourQuestionText: {
    marginBottom: "16px",
    "& textarea": {
      padding: "17px 24px",
      lineHeight: "24px",
    },
    "& div": {
      padding: 0,
      borderRadius: "8px",
      boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      backgroundColor: "#F5F7FA",
    },
    "& fieldset": {
      border: "none",
    },
  },
  disclaimerContainer: {
    padding: "8px",
    borderRadius: "8px",
    border: "1px solid #DBE3EF",
    marginBottom: "16px",
  },
  nextButton: {
    backgroundColor: "#DBE3EF !important",
    borderRadius: "8px",
    width: "123px",
    height: "56px",
    position: 'absolute',
    bottom: '0',
    right: '0',
    [theme.breakpoints.down("xs")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      width: "100%",
      marginBottom: '1em'
    },
  },
  nextImage: {
    marginLeft: '.3em'
  },
  basicFieldName: {
    marginBottom: "16px",
    width: "96%",
    "& input": {
      padding: "17px 24px",
    },
    "& div": {
      padding: 0,
      borderRadius: "8px",
      boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      backgroundColor: "#F5F7FA",
    },
    "& fieldset": {
      border: "none",
    },
  },
  basicFieldNameFullWidth: {
    marginBottom: "16px",
    width: "100%",
    "& input": {
      padding: "17px 24px",
    },
    "& div": {
      padding: 0,
      borderRadius: "8px",
      boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      backgroundColor: "#F5F7FA",
    },
    "& fieldset": {
      border: "none",
    },
  },
  select: {
    width: "100%",
    borderRadius: "8px",
    padding: "16px, 24px, 16px, 24px",
    "& .MuiPaper-root": {
      left: "24px !important",
      top: "280px !important",
      [theme.breakpoints.down("xs")]: {
        left: "14px !important",
      },
    },
    "& .MuiInputBase-root": {},
  },
  captchaDisclaimer: {
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0px",
    color: "#8C8CA1",
    marginTop: "27px",
    marginBottom: "27px",
  },
  popupSubmitButton: {
    backgroundColor: "#FF0F5F",
    padding: "16px 24px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px 11px",
    },
    "&:hover": {
      backgroundColor: "#FF0F5F",
    },
    borderRadius: '8px'
  },
  popupSubmitButtonText: {
    color: "#fff",
    paddingRight: "8px"
  },
  popupBackButton: {
    padding: "1em",
    background: '#FFFFFF',
    filter: 'drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))',
    [theme.breakpoints.down("xs")]: {
      padding: "16px 11px",
    },
    borderRadius: '8px'
  },
  popupBackButtonText: {
    paddingLeft: "8px"
  },
  ratingContainer: {
    marginBottom: '.8em',
    marginTop: '9px'
  },
  stepOneContainer: {
  },
  form: {
    height: '100%',
    position: 'relative'
  },
  errorLabel: {
    color: '#FF0F5F',
    display: 'block',
    width: '300px'
  },
  error: {
    border: '1px solid #FF0F5F',
    borderRadius: '8px',
    marginBottom: '0 !important'
  },
  ratingErrorLabel: {
    color: '#FF0F5F',
    display: 'block',
    lineHeight: '.9em'
  },
  disclaimer: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0px",
    color: "#2F2F4E",
    maxWidth: "312px",
    paddingLeft: "4px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "263px",
    },
  },
  disclaimerContainer: {
    padding: "8px",
    borderRadius: "8px",
    border: "1px solid #DBE3EF",
    marginBottom: "16px",
    position: "absolute",
    left: '0',
    bottom: '4em',
    [theme.breakpoints.down("xs")]:{
      marginTop: "16px",
      position: "initial",

    }
  },
  clientLabel: {

  },
  assetsFieldset: {
  },
  assetLabel: {
  },
  buttonsContainer: {
     position: 'absolute',
    bottom: '0',
    left: '0',
    [theme.breakpoints.down("xs")]:{
      position: 'relative',
    }
  },
  companyName: {
    color: '#262641',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '1em'
  },
  body: {
    color: '#2F2F4E',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    [theme.breakpoints.down("xs")]: {
      fontSize: '14px',
      lineHeight: '21px'
    },
  },
  stepTwoBody: {
    color: '#2F2F4E',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '1em'
  },
  paddedBottom: {
    paddingBottom: '1em'
  },
  emphasized: {
    fontWeight: '500'
  },
  recap: {
    display: "none",
  },
  root: {
    marginTop: '1em',
    marginBottom: '1.4em',
    '& .MuiPaginationItem-root': {
      minWidth: '29px !important',
      height: '29px !important',
      borderRadius: '14.5px !important',
      backgroundColor: '#F5F7FA',
      [theme.breakpoints.down("xs")]: {
        minWidth: '23px !important',
        height: '23px !important',
        borderRadius: '11.5px !important',
      },
    },
    '& .Mui-selected': {
      background: '#3C465F;',
      color: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      height: '29px',
      width: '29px',
      borderRadius: '14.5px'
    },
    '& .MuiPaginationItem-ellipsis': {
      paddingTop: '.8em'
    },
    '& .MuiPaginationItem-icon': {
      fontSize: '1.5rem'
    },
    '& ul > li > button:not(.Mui-selected),.MuiPaginationItem-ellipsis': {
      backgroundColor: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#2F2F4E',
      height: '3.5em',
      width: '3.5em',
      borderRadius: '1.75em'
    },

  },
}));


const GetDictionary = () => {
  const data = useStaticQuery(graphql`
      {
         allSanityDictionary(
          filter: {key: {in: [
            "rate_fund_popup_first_step_header_text",
"rate_fund_popup_recommend_text",
"rate_fund_popup_second_step_header_text",
"rate_fund_popup_second_step_header_text",
"rate_fund_popup_recommend_error_message",
"rate_fund_popup_how_would_you_recommend",
"rate_fund_popup_overall_performance",
"rate_fund_popup_overall_performance_required_error_message",
"rate_fund_popup_quality_of_service",
"rate_fund_popup_quality_of_service_required_error_message",
"rate_fund_popup_charges_and_fees",
"rate_fund_popup_rate_and_fees_required_error_message",
"rate_fund_popup_sustainability",
"rate_fund_popup_sustainability_required_error_message",
"rate_fund_popup_next_button_text",
"rate_fund_popup_review_placeholder",
"rate_fund_popup_your_details_text",
"rate_fund_popup_first_name_placeholder",
"rate_fund_popup_first_name_required_error_message",
"rate_fund_popup_surname_placeholder",
"rate_fund_popup_email_placeholder",
"rate_fund_popup_email_required_error_message",
"rate_fund_popup_email_invalid_error_message",
"rate_fund_popup_age_bracket_label",
"rate_fund_popup_age_bracket_required_error_message",
"rate_fund_popup_disclaimer_text",
"rate_fund_popup_back_button_text",
"rate_fund_popup_submit_button_text",
"rate_fund_popup_thank_you_message",
"rate_fund_popup_thank_you_message_body",
"rate_fund_popup_close_button_text"
          ]}}
        ) {
          nodes {
            value
            key
          }
        }
      }
    `)

  return data.allSanityDictionary.nodes;
}


export default function RateFundForm(props) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    clearErrors,
    setValue
  } = useForm();

  const [dictionary, setDictionary] = useState(GetDictionary);

  const [page, setPage] = useState(0);
  const [overallPerformance, setOverallPerformance] = useState(0);
  const [qualityOfService, setQualityOfService] = useState(0);
  const [chargesAndFees, setChargesAndFees] = useState(0);
  const [sustainability, setSustainability] = useState(0);


  const [firstStepHeaderText, setFirstStepHeaderText] = useState('');
  const [recommendText, setRecommendText] = useState('');
  const [secondStepHeaderText, setSecondStepHeaderText] = useState('');
  const [recommendationRequiredErrorMessage, setRecommendationRequiredErrorMessage] = useState('');
  const [howWouldYouRecommend, setHowWouldYouRecommend] = useState('');
  const [overallPerformanceText, setOverallPerformanceText] = useState('');
  const [overallPerformanceRequiredErrorMessage, setOverallPerformanceRequiredErrorMessage] = useState('');
  const [qualityOfServiceText, setQualityOfServiceText] = useState('');
  const [qualityOfServiceRequiredErrorMessage, setQualityOfServiceRequiredErrorMessage] = useState('');
  const [chargesAndFeesText, setChargesAndFeesText] = useState('');
  const [chargesAndFeesRequiredErrorMessage, setChargesAndFeesRequiredErrorMessage] = useState('');
  const [sustainabilityText, setSustainabilityText] = useState('');
  const [sustainabilityRequiredErrorMessage, setSustainabilityRequiredErrorMessage] = useState('');
  const [nextButtonText, setNextButtonText] = useState('');
  const [reviewPlaceholder, setReviewPlaceholder] = useState('');
  const [yourDetailsText, setYourDetailsText] = useState('');
  const [firstNamePlaceholder, setFirstNamePlaceholder] = useState('');
  const [firstNameRequirerdErrorMessage, setFirstNameRequirerdErrorMessage] = useState('');
  const [surnamePlaceholder, setSurnamePlaceholder] = useState('');
  const [emailPlaceholder, setEmailPlaceholder] = useState('');
  const [emailRequiredErrorMessage, setEmailRequiredErrorMessage] = useState('');
  const [emailInvalidErrorMessage, setEmailInvalidErrorMessage] = useState('');
  const [ageBracketLabel, setAgeBracketLabel] = useState('');
  const [ageRequiredErrorMessage, setAgeRequiredErrorMessage] = useState('');
  const [disclaimerText, setDisclaimerText] = useState('');
  const [backButtonText, setBackButtonText] = useState('');
  const [submitButtonText, setSubmitButtonText] = useState('');
  const [thankYouMessage, setThankYouMessage] = useState('');
  const [thankYouMessageBody, setThankYouMessageBody] = useState('');
  const [closeButtonText, setCloseButtonText] = useState('');
  const [reviewSubmitted, setReviewSubmitted] = useState(false);

  const classes = useStyles();


  useEffect(() => {
    (async () => {
      setFirstStepHeaderText(dictionary.find(x => x.key === 'rate_fund_popup_first_step_header_text').value);
      setRecommendText(dictionary.find(x => x.key === 'rate_fund_popup_recommend_text').value);
      setSecondStepHeaderText(dictionary.find(x => x.key === 'rate_fund_popup_second_step_header_text').value);
      setSecondStepHeaderText(dictionary.find(x => x.key === 'rate_fund_popup_second_step_header_text').value);
      setRecommendationRequiredErrorMessage(dictionary.find(x => x.key === 'rate_fund_popup_recommend_error_message').value);
      setHowWouldYouRecommend(dictionary.find(x => x.key === 'rate_fund_popup_how_would_you_recommend').value);
      setOverallPerformanceText(dictionary.find(x => x.key === 'rate_fund_popup_overall_performance').value);
      setOverallPerformanceRequiredErrorMessage(dictionary.find(x => x.key === 'rate_fund_popup_overall_performance_required_error_message').value);
      setQualityOfServiceText(dictionary.find(x => x.key === 'rate_fund_popup_quality_of_service').value);
      setQualityOfServiceRequiredErrorMessage(dictionary.find(x => x.key === 'rate_fund_popup_quality_of_service_required_error_message').value);
      setChargesAndFeesText(dictionary.find(x => x.key === 'rate_fund_popup_charges_and_fees').value);
      setChargesAndFeesRequiredErrorMessage(dictionary.find(x => x.key === 'rate_fund_popup_rate_and_fees_required_error_message').value);
      setSustainabilityText(dictionary.find(x => x.key === 'rate_fund_popup_sustainability').value);
      setSustainabilityRequiredErrorMessage(dictionary.find(x => x.key === 'rate_fund_popup_sustainability_required_error_message').value);
      setNextButtonText(dictionary.find(x => x.key === 'rate_fund_popup_next_button_text').value);
      setReviewPlaceholder(dictionary.find(x => x.key === 'rate_fund_popup_review_placeholder').value);
      setYourDetailsText(dictionary.find(x => x.key === 'rate_fund_popup_your_details_text').value);
      setFirstNamePlaceholder(dictionary.find(x => x.key === 'rate_fund_popup_first_name_placeholder').value);
      setFirstNameRequirerdErrorMessage(dictionary.find(x => x.key === 'rate_fund_popup_first_name_required_error_message').value);
      setSurnamePlaceholder(dictionary.find(x => x.key === 'rate_fund_popup_surname_placeholder').value);
      setEmailPlaceholder(dictionary.find(x => x.key === 'rate_fund_popup_email_placeholder').value);
      setEmailRequiredErrorMessage(dictionary.find(x => x.key === 'rate_fund_popup_email_required_error_message').value);
      setEmailInvalidErrorMessage(dictionary.find(x => x.key === 'rate_fund_popup_email_invalid_error_message').value);
      setAgeBracketLabel(dictionary.find(x => x.key === 'rate_fund_popup_age_bracket_label').value);
      setAgeRequiredErrorMessage(dictionary.find(x => x.key === 'rate_fund_popup_age_bracket_required_error_message').value);
      setDisclaimerText(dictionary.find(x => x.key === 'rate_fund_popup_disclaimer_text').value);
      setBackButtonText(dictionary.find(x => x.key === 'rate_fund_popup_back_button_text').value);
      setSubmitButtonText(dictionary.find(x => x.key === 'rate_fund_popup_submit_button_text').value);
      setThankYouMessage(dictionary.find(x => x.key === 'rate_fund_popup_thank_you_message').value);
      setThankYouMessageBody(dictionary.find(x => x.key === 'rate_fund_popup_thank_you_message_body').value);
      setCloseButtonText(dictionary.find(x => x.key === 'rate_fund_popup_close_button_text').value);
    })();
  }, [dictionary]);

  function handleOnClickingAwayHandler() {
    props.onClickingAwayHandler();
  }

  function onSubmit(data) {
    var rateFundModel = {
      FundId: props.fundId,
      FirstName: data.FirstName,
      Surname: data.LastName,
      Email: data.Email,
      Review: data.Review,
      Recommend: data.Recommend,
      QualityOfService: data.QualityOfService * 2,
      OverallPerformance: data.OverallPerformance * 2,
      ChargesAndFees: data.ChargesAndFees * 2,
      Sustainability: data.Sustainability * 2,
      AgeBracketId: data.AgeBracket
    }
   // console.log('ratefund model:', rateFundModel);

    fetch(process.env.GATSBY_QUESTIONAPI + "/AddFundRating/AddRating", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(rateFundModel),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => {
        console.error('Error:', error);
      });

    setStep(3);

    setReviewSubmitted(true);
  }
  const [step, setStep] = React.useState(1);
  const [selectedPage, setSelectedPage] = React.useState(0);

  // var apiURL = process.env.GATSBY_QUESTIONAPI;
  // console.log(apiURL)
  function handleNext(data) {
    data.QualityOfService && data.Recommend && data.OverallPerformance && data.ChargesAndFees && setStep(2);
  }
  function handleBack(data) {
    setStep(1);
  }
  function handlePageItemClick(event) {
    var newValue = parseInt(event.target.innerText);

    setSelectedPage(newValue);
    setValue('Recommend', newValue, { shouldDirty: true });
    clearErrors('Recommend');
  }

  useEffect(() => {
    (async () => {
      if (reviewSubmitted) {
        if (window.dataLayer) {
          var model = {
            Location: window.location.href,
            FundId: props.fundId
          };

          window.dataLayer.push({ 'fund_review_submitted_value': JSON.stringify(model) });

          window.dataLayer.push({ event: 'fund_review_submitted' });
        }

        //braze
        //window.trackReviewSubmit(model)
      }
    })();
  }, [reviewSubmitted]);

  return (
    <form className={classes.form}>
      <RecaptchaLoader />
      <Recaptcha
        ref={e => recaptchaInstance = e}
        sitekey="6LcWa-IbAAAAAAY8dsZcdQVnp8ycB-9iM0dbl6g8"
        size="invisible"
        verifyCallback={handleSubmit(onSubmit)}
        className={classes.recap}
      />
      {(step === 1 || step === 2) && (
        <Grid
          item
          container
          justify="space-between"
          alignItems="center"
          className={classes.popupHeader}
        >
          <Typography variant="h4" className={classes.sendAMessage}>
            {step === 1 && (props.fundName + ' ' + firstStepHeaderText)} {step === 2 && secondStepHeaderText}
          </Typography>

          <Button
            className={classes.collapsePopupButton}
            onClick={() => handleOnClickingAwayHandler()}
          >
            <img src={CloseSquare} alt="CloseSquare" />
          </Button>
        </Grid>
      )}
      {step === 1 && (
        <div className={classes.stepOneContainer}>
          <Typography variant="h4" className={classes.companyName}>
            {' '}
          </Typography>
          <Typography variant="h4" className={classes.body}>
            {recommendText}
          </Typography>
          <Controller
            name="Recommend"
            control={control}
            rules={{ required: true }}
            render={(field) => (
              <div className={`${errors.Recommend?.type === "required" ? classes.error : ''} ${classes.root}`}>
                <PaginationItem
                  page={1}
                  selected={selectedPage == 1}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={2}
                  selected={selectedPage == 2}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={3}
                  selected={selectedPage == 3}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={4}
                  selected={selectedPage == 4}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={5}
                  selected={selectedPage == 5}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={6}
                  selected={selectedPage == 6}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={7}
                  selected={selectedPage == 7}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={8}
                  selected={selectedPage == 8}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={9}
                  selected={selectedPage == 9}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={10}
                  selected={selectedPage == 10}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
              </div>
            )}
          />
          {errors.Recommend?.type === "required" &&
            <span className={classes.ratingErrorLabel}>
            {recommendationRequiredErrorMessage}
            </span>}

          <Typography variant="h4" className={`${classes.body} ${classes.paddedBottom} ${classes.emphasized}`}>
            {howWouldYouRecommend}
          </Typography>

          <Typography variant="h4" className={classes.body}>
            {overallPerformanceText}
          </Typography>
          <Controller
            name="OverallPerformance"
            control={control}
            rules={{ required: true }}
            render={(field) => (
              <>
                <Rating
                  name="OverallPerformance"
                  precision={1}
                  value={overallPerformance}
                  className={`${errors.OverallPerformance?.type === "required" ? classes.error : ''} ${classes.ratingContainer}`}
                  onChange={(event, newValue) => {
                    setOverallPerformance(newValue);
                    setValue('OverallPerformance', newValue, { shouldDirty: true });
                    clearErrors('OverallPerformance');
                  }}
                  icon={
                    <img
                      src={StarIcon}
                      alt="StarIcon"
                      className={classes.starImage}
                    />
                  }
                  emptyIcon={
                    <img
                      src={EmptyStarIcon}
                      alt="EmptyStarIcon"
                      className={classes.starImage}
                    />
                  }
                />
              </>

            )}
          />
          {errors.OverallPerformance?.type === "required" &&
            <span className={classes.ratingErrorLabel}>
            {overallPerformanceRequiredErrorMessage}
            </span>}
          <Typography variant="h4" className={classes.body}>
            {qualityOfServiceText}
          </Typography>
          <Controller
            name="QualityOfService"
            control={control}
            rules={{ required: true }}
            render={(field) => (
              <>
                <Rating
                  name="QualityOfService"
                  precision={1}
                  value={qualityOfService}
                  className={`${errors.QualityOfService?.type === "required" ? classes.error : ''} ${classes.ratingContainer}`}
                  onChange={(event, newValue) => {
                    setQualityOfService(newValue);
                    setValue('QualityOfService', newValue, { shouldDirty: true });
                    clearErrors('QualityOfService');
                  }}
                  icon={
                    <img
                      src={StarIcon}
                      alt="StarIcon"
                      className={classes.starImage}
                    />
                  }
                  emptyIcon={
                    <img
                      src={EmptyStarIcon}
                      alt="EmptyStarIcon"
                      className={classes.starImage}
                    />
                  }
                />
              </>

            )}
          />
          {errors.QualityOfService?.type === "required" &&
            <span className={classes.ratingErrorLabel}>
            {qualityOfServiceRequiredErrorMessage}
            </span>}
          <Typography variant="h4" className={classes.body}>
            {chargesAndFeesText}
          </Typography>
          <Controller
            name="ChargesAndFees"
            control={control}
            rules={{ required: true }}
            render={(field) => (
              <>
                <Rating
                  name="ChargesAndFees"
                  precision={1}
                  value={chargesAndFees}
                  className={`${errors.ChargesAndFees?.type === "required" ? classes.error : ''} ${classes.ratingContainer}`}
                  onChange={(event, newValue) => {
                    setChargesAndFees(newValue);
                    setValue('ChargesAndFees', newValue, { shouldDirty: true });
                    clearErrors('ChargesAndFees');
                  }}
                  icon={
                    <img
                      src={StarIcon}
                      alt="StarIcon"
                      className={classes.starImage}
                    />
                  }
                  emptyIcon={
                    <img
                      src={EmptyStarIcon}
                      alt="EmptyStarIcon"
                      className={classes.starImage}
                    />
                  }
                />
              </>

            )}
          />
          {errors.ChargesAndFees?.type === "required" &&
            <span className={classes.ratingErrorLabel}>
            {chargesAndFeesRequiredErrorMessage}
            </span>}
          <Typography variant="h4" className={classes.body}>
            {sustainabilityText}
          </Typography>
          <Controller
            name="Sustainability"
            control={control}
            rules={{ required: true }}
            render={(field) => (
              <>
                <Rating
                  name="Sustainability"
                  precision={1}
                  value={sustainability}
                  className={`${errors.Sustainability?.type === "required" ? classes.error : ''} ${classes.ratingContainer}`}
                  onChange={(event, newValue) => {
                    setSustainability(newValue);
                    setValue('Sustainability', newValue, { shouldDirty: true });
                    clearErrors('Sustainability');
                  }}
                  icon={
                    <img
                      src={StarIcon}
                      alt="StarIcon"
                      className={classes.starImage}
                    />
                  }
                  emptyIcon={
                    <img
                      src={EmptyStarIcon}
                      alt="EmptyStarIcon"
                      className={classes.starImage}
                    />
                  }
                />
              </>

            )}
          />
          {errors.Sustainability?.type === "required" &&
            <span className={classes.ratingErrorLabel}>
            {sustainabilityRequiredErrorMessage}
            </span>}
          <Button
            className={classes.nextButton}
            onClick={handleSubmit(handleNext)}
          >
            <Typography variant="button">{nextButtonText}</Typography>{" "}
            <img src={ArrowRightSquare} alt="ArrowRightSquare" className={classes.nextImage} />
          </Button>
        </div>
      )}
      {step === 2 && (
        <div>
          <Grid item container>
            <Grid item xs={12}>
              <Controller
                name="Review"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={3}
                    placeholder={reviewPlaceholder}
                    className={`${errors.Review?.type === "required" ? classes.error : ''} ${classes.yourQuestionText}`}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" className={`${classes.stepTwoBody} ${classes.emphasized}`}>
                {yourDetailsText}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="FirstName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder={firstNamePlaceholder}
                    className={`${errors.FirstName?.type === "required" ? classes.error : ''} ${classes.basicFieldName}`}
                    fullWidth
                  />
                )}
              />
              {errors.FirstName?.type === "required" &&
                <span className={classes.errorLabel}>
                  {firstNameRequirerdErrorMessage}
                </span>}
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <Controller
                name="LastName"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder={surnamePlaceholder}
                    className={classes.basicFieldName}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
          <Controller
            name="Email"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format"
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                placeholder={emailPlaceholder}
                className={`${errors.Email?.type === "required" || errors.Email?.type === "pattern" ? classes.error : ''} ${classes.basicFieldNameFullWidth}`}
                fullWidth
              />
            )}
          />
          {(errors.Email?.type === "required" &&
            <span className={classes.errorLabel}>
              {emailRequiredErrorMessage}
            </span>) || (
              errors.Email?.type === "pattern" &&
              <span className={classes.errorLabel}>
                {emailInvalidErrorMessage}
              </span>
            )}
          <Controller
            {...register("AgeBracketId")}
            name="AgeBracket"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <FormControl className={classes.select}>
                <InputLabel id="select-outlined-label">
                  {field.value === undefined && ageBracketLabel}
                </InputLabel>
                <Select
                  displayEmpty
                  labelId="select-outlined-label"
                  id="select-outlined"
                  className={`${errors.AgeBracket?.type === "required" ? classes.error : ''} ${classes.select}`}
                  {...field}
                  MenuProps={{ disablePortal: true }}
                >
                  <MenuItem
                    {...field}
                    value="6a0dc94c-e985-4009-90a1-75822279a85a"
                  >
                    18-24
                  </MenuItem>
                  <MenuItem
                    {...field}
                    value="782ced81-b1d0-4caa-b0b2-c71e02f4bb82"
                  >
                    25-34
                  </MenuItem>
                  <MenuItem
                    {...field}
                    value="d6c23e7c-9cc3-4497-89d8-984ca3c288a9"
                  >
                    35-44
                  </MenuItem>
                  <MenuItem
                    {...field}
                    value="2c5d2095-3de5-483a-b779-6ac5bb984771"
                  >
                    45-54
                  </MenuItem>
                  <MenuItem
                    {...field}
                    value="9b0367c4-069a-41a8-b890-56753b6a9639"
                  >
                    55-64
                  </MenuItem>
                  <MenuItem
                    {...field}
                    value="858a210e-716a-42ef-9176-2a479c8438a3"
                  >
                    65+
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {(errors.AgeBracket?.type === "required" &&
            <span className={classes.errorLabel}>
            {ageRequiredErrorMessage}
            </span>) }
          <Grid
            item
            container
            alignItems="flex-start"
            className={classes.disclaimerContainer}
          >
            <Grid item>
              <img src={DangerTriangle} alt="DangerTriangle" />
            </Grid>
            <Grid item className={classes.disclaimer}>
              <Typography variant="body2" className={classes.disclaimer}>
                {disclaimerText}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item justify="space-between" className={classes.buttonsContainer}>
            <Button onClick={() => handleBack()} className={classes.popupBackButton}>
              <img src={ArrowLeft} alt="ArrowLeft" />
              <Typography variant="button" className={classes.popupBackButtonText}>{backButtonText}</Typography>
            </Button>
            <Button type="submit" className={classes.popupSubmitButton} onClick={executeCaptcha}>
              <Typography variant="button" className={classes.popupSubmitButtonText}>{submitButtonText} </Typography>
              <img src={Send} alt="Send" />
            </Button>
          </Grid>
        </div>
      )}

      {step === 3 && (
        <RateFundStep3MessageSent onClickingAwayHandler={handleOnClickingAwayHandler} thankYouMessage={thankYouMessage} thankYouMessageBody={thankYouMessageBody} closeButtonText={closeButtonText} />
      )}
    </form>
  );
}
{
}
