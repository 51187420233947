import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Heart from "../../icon/HeartRating.svg";
import ContainerForSVG from "../../containerForSVG";
import FundPopover from "./fundPopover";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#8C8CA1",
    paddingBottom: "6px",
  },
  percentage: {
    color: "#3C465F",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "27px",
    letterSpacing: "0em",
  },
  container: {
    padding: "0 0 0 6px",
    [theme.breakpoints.down("1300")]: {
      padding: "0 0 0 3px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  containerLargeVariant:{
    padding: "0 0 0 12px",
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  heartIcon:{
    paddingRight: "8px",
  },
  dangerCircle: {
    paddingLeft: "4px",
  },
  containerForFundManagerInfo:{
    padding: "0",
  },
}));

function Row(props) {
  const classes = useStyles();

  var amountVal = props.amount;

  return (
    <div className={classes.row}>
      <Typography variant="body1" className={classes.percentage}>
        <ContainerForSVG>
          <img src={Heart} alt="HeartIcon" className={classes.heartIcon} />
          {amountVal}
        </ContainerForSVG>
      </Typography>
    </div>
  );
}

export default function ReaderRating(props) {
  const classes = useStyles();
  var amountVal = "n/a"

  if (props.amount != "0") {
    amountVal = props.amount + "%";
  }
  return (
    <Grid className={props.isForFundManagerInfo? classes.containerForFundManagerInfo:props.isLargeVariant? classes.containerLargeVariant : classes.container}>
                <FundPopover title={props.title} disclaimerLocation={props.disclaimerLocation} />


      <Row amount={amountVal} />
    </Grid>
  );
}
