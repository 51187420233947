import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import TickYes from "../../icon/TickSquareWhiteOnGreen.svg";
import ContainerForSVG from "../../containerForSVG";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#262641",
  },
  tickYesIcon: {
    paddingRight: "8px",
  },
  row: {
    paddingBottom: "4px",
  },
  title: {
    color: "#8C8CA1",
    paddingBottom: "6px",
  },

}));

function Row(props) {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <Typography variant="body1" className={classes.text}>
        <ContainerForSVG>
          <img
            src={TickYes}
            alt="TickYesIcon"
            className={classes.tickYesIcon}
          />
          {props.text}
        </ContainerForSVG>
      </Typography>
    </div>
  );
}

export default function FundTypeFundInfoVariant(props) {
  const classes = useStyles();
  return (
    <Grid>
      <Typography variant="body2" className={classes.title}>
        {props.title}
      </Typography>
      {props.Equity === "True" && <Row text="Equity" />}
      {props.ActivePassive === "True" && <Row text="Active" />}
      {props.ActivePassive === "False" && <Row text="Passive" />}
      {props.Fixed_Interest === "True" && <Row text="Fixed Interest" />}
      {props.Multi_Asset === "True" && <Row text="Multi Asset" />}
      {props.Sustainable_ESG === "True" && <Row text="Sustainable ESG" />}
    </Grid>
  );
}
