import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import Crown from "../../icon/crown-16x16.svg";
import EmptyCrown from "../../icon/crown-empty-16x16.svg";
import FundPopover from "./fundPopover";

const useStyles = makeStyles((theme) => ({
  ratingImage: {
    padding: "2px",
  },
  ratingImageLargeVariant:{
    padding: "3px",
  }
}));

export default function RatingCustom(props) {
  const classes = useStyles();

  if (props.rating == 0) {
    return (
      <Grid>
        <FundPopover title="Rating" disclaimerLocation="fund_list_disclaimer_text_rating" />
        n/a
      </Grid>
    );
  }
  else {
    return (
      <Grid>
        <FundPopover title="Rating" disclaimerLocation="fund_list_disclaimer_text_rating" />
        <Rating
          readOnly
          name="Rating"
          precision={1}
          value={props.rating}
          icon={
            <img src={Crown} alt="CrownIcon" className={props.isLargeVariant ? classes.ratingImageLargeVariant : classes.ratingImage} />
          }
          emptyIcon={
            <img
              src={EmptyCrown}
              alt="EmptyCrownIcon"
              className={props.isLargeVariant ? classes.ratingImageLargeVariant : classes.ratingImage}
            />
          }
        />
      </Grid>
    );
  }

 
}
