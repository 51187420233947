import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import FundPopover from "./fundPopover";


const useStyles = makeStyles((theme) => ({
  title: {
    color: "#8C8CA1",
    paddingBottom: "8px",
  },
  percentage: {
    color: "#2F2F4E",
  },
  container: {
    padding: "0 6px",
    [theme.breakpoints.down("1300")]: {
      padding: "0 3px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  firstContainer:{
    padding: "0 6px 0 0",
    [theme.breakpoints.down("1300")]: {
      padding: "0 3px 0 0",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  containerLargeVariant: {
    padding: "0 12px",
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  firstContainerLargeVariant:{
    padding: "0 12 0 0",
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  containerForFundManagerInfo:{
    padding: "0",
  },
  dangerCircle: {
    paddingLeft: "4px",
  },
}));

export default function Info(props) {
  const classes = useStyles();
  var amountVal = "n/a"
  if (props.amount != "0.00%") {
    amountVal = props.amount;
  }
  return (
    <Grid className={props.isForFundManagerInfo? classes.containerForFundManagerInfo:props.isLargeVariant ? (props.isFirst ? classes.firstContainerLargeVariant : classes.containerLargeVariant) : (props.isFirst ? classes.firstContainer : classes.container)}>
      <FundPopover title={props.title} disclaimerLocation={props.disclaimerLocation}/>
      <Typography variant="body1" className={classes.percentage}>
        {amountVal != '0%' ? amountVal : ''}
      </Typography>
    </Grid>
  );
}
//className={props.isFirst ? classes.firstContainer : classes.container}
