import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import TickYes from "../../icon/TickSquareWhiteOnGreen.svg";
import ContainerForSVG from "../../containerForSVG";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#262641",
    fontSize: "14px",
    fontWeight: "500",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      letterSpacing: "0",
      lineHeight: "18px",
    },
  },
  managerText:{
    color: "#262641",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "16px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      letterSpacing: "0",
      lineHeight: "18px",
    },
  },
  title: {
    color: "#8C8CA1",
    paddingBottom: "8px",
  },

  fundType: {
    padding: "8px",
    border: "0.5px solid #DBE3EF",
    borderRadius: "4px",
    boxShadow: "0px 2px 4px 0px #4040401A",

  },
  managerFundType: {
    padding: "8px 6px",
    border: "0.5px solid #DBE3EF",
    borderRadius: "4px",
    boxShadow: "0px 2px 4px 0px #4040401A",

  },
  fundTypeContainer: {
    padding: "0 8px 0 0",
    [theme.breakpoints.down("1280")]: {
      padding: "0 16px 16px 0",
    },
    
  },
  fundTypeContainerFirst:{
    padding: "0 8px 0 0",
    [theme.breakpoints.down("1280")]: {
      padding: "0 16px 16px 0",
    },
  }
}));

function FundType(props) {
  const classes = useStyles();

  return (
    <Grid className={classes.fundTypeContainer}>
      <Grid className={classes.fundType}>
        <Typography variant="body1" className={props.isForFundManagerInfo?classes.managerText:classes.text}>
          {props.text}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function FundTypeFundInfoVariant(props) {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Typography variant="body2" className={classes.title}>
        {props.title}
      </Typography>
      <Grid container>
        {props.Equity === "True" && <Grid className={classes.fundTypeContainerFirst}>
      <Grid className={classes.fundType}>
        <Typography variant="body1" className={props.isForFundManagerInfo?classes.managerText:classes.text}>
        Equity
        </Typography>
      </Grid>
    </Grid>}
        {props.ActivePassive === "True" && <FundType isForFundManagerInfo={props.isForFundManagerInfo} text="Active" />}
        {props.ActivePassive === "False" && <FundType isForFundManagerInfo={props.isForFundManagerInfo} text="Passive" />}
        {props.Fixed_Interest === "True" && <FundType isForFundManagerInfo={props.isForFundManagerInfo} text="Fixed Interest" />}
        {props.Multi_Asset === "True" && <FundType isForFundManagerInfo={props.isForFundManagerInfo} text="Multi Asset" />}
        {props.Sustainable_ESG === "True" && (
          <FundType isForFundManagerInfo={props.isForFundManagerInfo} text="Sustainable ESG" />
        )}
      </Grid>
    </Grid>
  );
}
