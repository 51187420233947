import React, { useState, useEffect } from "react";
import Popover from "@material-ui/core/Popover";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DangerCircle from "../../icon/Danger Circle.svg";
import ContainerForSVG from "../../containerForSVG";
import { createClient } from "../../../lib/helpers";
import { useStaticQuery, graphql } from "gatsby"


const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    height: "auto",
    maxWidth: "300px",
    boxShadow: "none",
    backgroundColor: "transparent",
    padding: "4px 2px 12px 2px",
  },
  popoverInner: {
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding: "16px",
  },
  title: {
    color: "#8C8CA1",
    paddingBottom: "11px",
  },
  titleForSlider:{
    color: "#2F2F4E",
    paddingBottom: "6px"
  },
  dangerCircle: {
    paddingLeft: "6px",
    cursor: "pointer",
  },
  arrowLeft: {
    width: 0,
    height: 0,
    marginLeft: "6px",
    marginTop: "50px",
    boxShadow: "0px 2px 4px 0px #4040401A",
    backgroundColor: "transparent",
    borderLeft: "8px solid transparent",
    borderTop: "16px solid grey",
    borderRight: "8px solid transparent",
    position: "absolute",
  },
  popoverInnerText:{
fontSize: "12px",
fontWeight: 400,
lineHeight: "18px",
letterSpacing: "0px",
color: "#2F2F4E",

  }
}));

const GetDictionary = () => {

  const data = useStaticQuery(graphql`
      {
         allSanityDictionary(
          filter: {key: {in: ["fund_list_slider_text_rating","fund_list_slider_text_risk","fund_list_disclaimer_text_rating",
            "fund_list_disclaimer_text_risk","fund_list_disclaimer_text_return1yr","fund_list_disclaimer_text_return5yr"
            ,"fund_list_disclaimer_text_cost","fund_list_disclaimer_text_reader_rating"]}}
        ) {
          nodes {
            value
            key
          }
        }
      }
    `)

  return data.allSanityDictionary.nodes;
}


export default function FundPopover(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [helpText, setHelpText] = useState("Loading");

  const [dictionary, setDictionary] = useState(GetDictionary());

  let help;
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  
  useEffect(() => {
    (async () => {
     
      setHelpText(
        dictionary.find((x) => x.key === props.disclaimerLocation).value
      );
    })();
  }, []);

  return (
    <div>
      <Typography
        variant={`${props.isForSlider == "true" ? "body1" : "body2"}`}
        className={`${props.isForSlider=="true" ? classes.titleForSlider : classes.title}`}
      >
        <ContainerForSVG>
          {props.title}

          <img
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            src={DangerCircle}
            alt="DangerCircleIcon"
            className={classes.dangerCircle}
          />
        </ContainerForSVG>
      </Typography>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
      >
        {/* {open && <div className={classes.arrowLeft} />} */}
        <Grid className={classes.popoverInner}>
          <Typography className={classes.popoverInnerText}> {helpText == null ? "Loading" : helpText}</Typography>
        </Grid>
      </Popover>
    </div>
  );
}
